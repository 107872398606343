import { template as template_b3a68330b8784e96a2c920b819d823bb } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_b3a68330b8784e96a2c920b819d823bb(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
