import { template as template_b64a47819f8c46bfa66c8faaf7738df4 } from "@ember/template-compiler";
const FKLabel = template_b64a47819f8c46bfa66c8faaf7738df4(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
